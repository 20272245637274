.instagram {

    iframe {
        margin: 0 auto;
        @media (max-width: 768px) {
            height: 700px;
        }

        @media (max-width: 500px) {
            height: 500px;
        }

        @media (max-width: 400px) {
            height: 400px;
        }
    }
}