/*---------------------
  Hero
-----------------------*/
.hero {
    padding-bottom: 50px;

    &.hero-normal {
        padding-bottom: 30px;

        .hero__categories {
            position: relative;
            ul {
                display: none;
                position: absolute;
                left: 0;
                top: 46px;
                width: 100%;
                z-index: 10;
                background: $white-color;
            }
        }

        .hero__search {
            margin-bottom: 0;
        }
    }
}

.hero__categories__all {
    background: $primary-color;
    position: relative;
    padding: 10px 25px 10px 40px;
    cursor: pointer;

    i {
        font-size: 16px;
        color: $white-color;
        margin-right: 10px;
    }

    span {
        font-size: 18px;
        font-weight: 600;
        color: $white-color;
    }

    &:after {
        position: absolute;
        right: 18px;
        top: 9px;
        content: '\33';
        font-family: 'ElegantIcons';
        font-size: 18px;
        color: $white-color;
    }
}

.hero__categories {

    h5 {
        text-align: center;
        font-weight: bold;
    }

    ul {
        border: 1px solid $border;
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 12px;

        li {
            list-style: none;

            .item {
                font-size: 16px;
                color: $normal-color;
                line-height: 39px;
                display: flex;
                position: relative;
                justify-content: space-between;
                padding-left: 15px;
                &:hover {
                    background-color: #ebebeb;
                }
    
                span {
                    display: block;
                    position: relative;
                    height: 40px;
                    width: 37px;
                    z-index: 9;
                    &::after {
                        display: flex;
                        position: absolute;
                        right: 18px;
                        top: 10px;
                        background-color: white;
                        content: "3";
                        border-radius: 50%;
                        font-family: "ElegantIcons";
                        height: 18px;
                        font-size: 18px;
                        justify-content: center;
                        align-items: center;
                        transition: all .5s;
                    }
                }

                &.active {
                    background-color: #ebebeb;
                    transition: all .5s;
                    span::after {
                        transform: rotateZ(-90deg);
                    }
                }
            }

            .sublist {
                background-color: white;
                display: none;
                border: 1px solid #ebebeb;
                padding-left: 0;
                padding-top: 10px;
                padding-bottom: 12px;
                position: absolute;
                margin-top: -40px;
                right: -220px;
                width: 220px;

                @media (max-width: 768px) {
                    right: 0;
                    z-index: 30;
                }

                a {
                    font-size: 16px;
                    line-height: 39px;
                    display: flex;
                    position: relative;
                    padding-left: 15px;
                    &:hover {
                        background-color: #ebebeb;
                    }
                }

                &.active {
                    background-color: #ebebeb;
                    transition: all .5s;
                    span::after {
                        transform: rotateZ(-90deg);
                    }
                }
            }

            .lastlist {
                background-color: white;
                display: none;
                border: 1px solid #ebebeb;
                padding-left: 0;
                padding-top: 10px;
                padding-bottom: 12px;
                position: absolute;
                margin-top: -40px;
                right: -200px;
                width: 200px;

                @media (max-width: 768px) {
                    right: 0;
                    z-index: 20;
                }

                a {
                    padding-left: 15px;
                    &:hover {
                        background-color: #ebebeb;
                    }
                }
            }
        }
    }
}

.hero__search {
    overflow: hidden;
    margin-bottom: 30px;
}
.home__slider {
    margin-bottom: 50px;
    .owl-dots {
        display: none;
    }
}

.hero__search__form {
    width: 610px;
    height: 50px;
    border: 1px solid $border;
    position: relative;
    float: left;

    form {

        .hero__search__categories {
            width: 30%;
            float: left;
            font-size: 16px;
            color: $normal-color;
            font-weight: 600;
            padding-left: 18px;
            padding-top: 11px;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                top: 14px;
                height: 20px;
                width: 1px;
                background: $black-color;
                opacity: .1;
                content: "";
            }

            span {
                position: absolute;
                right: 14px;
                top: 14px;
            }
        }

        input {
            width: 70%;
            border: none;
            height: 48px;
            font-size: 16px;
            color: #b2b2b2;
            padding-left: 20px;

            &::placeholder {
                color: #b2b2b2;
            }
        }

        button {
            position: absolute;
            right: 0;
            top: -1px;
            height: 50px;
        }
    }
}

.hero__search__phone {
    float: right;
}

.hero__search__phone__icon {
    font-size: 18px;
    color: $primary-color;
    height: 50px;
    width: 50px;
    background: $background;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    float: left;
    margin-right: 20px;
}

.hero__search__phone__text {
    overflow: hidden;
    max-width: 140px;

    h5 {
        color: $normal-color;
        font-weight: 600;
        margin-bottom: 5px;
    }

    span {
        font-size: 14px;
        color: $para-color;
    }
}

.hero__item {
    height: 400px;
    display: flex;
    align-items: center;
}

.hero__text {

    span {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 4px;
        color: $primary-color;
    }

    h2 {
        font-size: 46px;
        color: $heading-color-2;
        line-height: 52px;
        font-weight: 600;
        margin: 10px 0;
    }

    p {
        margin-bottom: 35px;
    }
}