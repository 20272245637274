/*---------------------
  Breadcrumb
-----------------------*/
.breadcrumb-section {
    display: flex;
    align-items: center;
    padding: 45px 0 40px;
}

.breadcrumb__text {

    h2 {
        font-size: 46px;
        color: $white-color;
        font-weight: 600;
    }
}

.breadcrumb__option {

    a {
        display: inline-block;
        font-size: 16px;
        color: $white-color;
        font-weight: 600;
        margin-right: 20px;
        position: relative;

        &:after {
            position: absolute;
            right: -12px;
            top: 13px;
            height: 1px;
            width: 10px;
            background: $white-color;
            content: '';
        }
    }

    span {
        display: inline-block;
        font-size: 16px;
        color: $white-color;
    }
}