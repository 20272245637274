/*---------------------
  Checkout
-----------------------*/
.checkout {
    padding-top: 80px;
    padding-bottom: 60px;

    h6 {
        color: #999999;
        text-align: center;
        background: $background;
        border-top: 1px solid #6AB963;
        padding: 12px 0 12px;
        margin-bottom: 75px;

        span {
            font-size: 16px;
            color: #6AB963;
            margin-right: 5px;
        }

        a {
            text-decoration: underline;
            color: #999999;
        }
    }
}

.checkout__form {

    h4 {
        color: $normal-color;
        font-weight: 600;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 20px;
        margin-bottom: 25px;
    }

    p {
        column-rule: #b2b2b2;
    }
}

.checkout__input {
    margin-bottom: 24px;

    p {
        color: $normal-color;
        margin-bottom: 20px;

        span {
            color: #dd2222;
        }
    }

    input {
        width: 100%;
        height: 46px;
        border: 1px solid $border;
        padding-left: 20px;
        font-size: 16px;
        color: #b2b2b2;
        border-radius: 4px;

        &.checkout__input__add {
            margin-bottom: 20px;
        }

        &::placeholder {
            color: #b2b2b2;
        }
    }
}

.checkout__input__checkbox {
    margin-bottom: 10px;

    label {
        position: relative;
        font-size: 16px;
        color: $normal-color;
        padding-left: 40px;
        cursor: pointer;

        input {
            position: absolute;
            visibility: hidden;

            &:checked {

                &~.checkmark {
                    background: $primary-color;
                    border-color: $primary-color;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        .checkmark {
            position: absolute;
            left: 0;
            top: 4px;
            height: 16px;
            width: 14px;
            border: 1px solid #a6a6a6;
            content: '';
            border-radius: 4px;

            &:after {
                position: absolute;
                left: 1px;
                top: 1px;
                width: 10px;
                height: 8px;
                border: solid white;
                border-width: 3px 3px 0px 0px;
                -webkit-transform: rotate(127deg);
                -ms-transform: rotate(127deg);
                transform: rotate(127deg);
                content: '';
                opacity: 0;
            }
        }
    }
}

.checkout__order {
    background: $background;
    padding: 40px;
    padding-top: 30px;

    h4 {
        color: $normal-color;
        font-weight: 600;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .checkout__order__products {
        font-size: 18px;
        color: $normal-color;
        font-weight: 600;
        margin-bottom: 10px;

        span {
            float: right;
        }
    }

    ul {
        margin-bottom: 12px;

        li {
            font-size: 16px;
            color: $para-color;
            line-height: 40px;
            list-style: none;

            span {
                font-weight: 600;
                float: right;
            }
        }
    }

    .checkout__order__subtotal {
        font-size: 18px;
        color: $normal-color;
        font-weight: 600;
        border-bottom: 1px solid #e1e1e1;
        border-top: 1px solid #e1e1e1;
        padding-bottom: 15px;
        margin-bottom: 15px;
        padding-top: 15px;

        span {
            float: right;
        }
    }

    .checkout__input__checkbox {

        label {
            padding-left: 20px;
        }
    }

    .checkout__order__total {
        font-size: 18px;
        color: $normal-color;
        font-weight: 600;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 15px;
        margin-bottom: 25px;

        span {
            float: right;
            color: #dd2222;
        }
    }

    button {
        font-size: 18px;
        letter-spacing: 2px;
        width: 100%;
        margin-top: 10px;
    }
}