/*---------------------
  Blog
-----------------------*/
.blog__item {
    margin-bottom: 60px;
}

.blog__item__pic {

    img {
        min-width: 100%;
    }
}

.blog__item__text {
    padding-top: 25px;

    ul {
        margin-bottom: 15px;

        li {
            font-size: 16px;
            color: #b2b2b2;
            list-style: none;
            display: inline-block;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    h5 {
        margin-bottom: 12px;

        a {
            font-size: 20px;
            color: $normal-color;
            font-weight: 600;
        }
    }

    p {
        margin-bottom: 25px;
    }

    .blog__btn {
        display: inline-block;
        font-size: 14px;
        color: $normal-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: 1px solid #b2b2b2;
        padding: 14px 20px 12px;
        border-radius: 25px;

        span {
            position: relative;
            top: 1px;
            margin-left: 5px;
        }
    }
}

.blog__pagination {
    @extend .product__pagination;
    padding-top: 5px;
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        top: -29px;
        height: 1px;
        width: 100%;
        background: $black-color;
        opacity: .1;
        content: '';
    }
}