/*------------------------------------------------------------------
[Table of contents]

1.  Template default CSS
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Helper Css
3.  Header Section
4.  Hero Section
5.  Service Section
6.  Categories Section
7.  Featured Section
8.  Latest Product Section
9.  Contact
10.  Footer Style
-------------------------------------------------------------------*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/
html,
body {
  height: 100%;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  font-family: $primary-font;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 16px;
  font-family: 'Cairo', sans-serif;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px 0;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: $white-color;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/
.section-title {
  margin-bottom: 50px;
  text-align: center;

  h2 {
    color: $normal-color;
    font-weight: 600;
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      bottom: -15px;
      right: 0;
      height: 4px;
      width: 80px;
      background: $primary-color;
      content: "";
      margin: 0 auto;
    }
  }
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

.text-white {
  color: #fff !important;
}
.text-black {
  color: #252525 !important;
}
/* buttons */
.primary-btn {
  display: inline-block;
  font-size: 14px;
  padding: 10px 28px 10px;
  color: $white-color;
  text-transform: uppercase;
  font-weight: 600;
  background: $primary-color;
  border: 0;
  letter-spacing: 2px;
}

.site-btn {
  font-size: 14px;
  color: $white-color;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 30px 12px;
  background: $primary-color;
  border: none;
  height: 50px;
}

.site-btn-min {
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 17px;
  background: #7fad39;
  border: none;
  height: 28px;
}

/* Preloder */
#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}