/*---------------------
  Sidebar
-----------------------*/
.sidebar__item {
    margin-bottom: 35px;

    &.sidebar__item__color--option {
        overflow: hidden;
    }

    h4 {
        color: $normal-color;
        font-weight: 600;
        margin-bottom: 25px;
    }

    h5 {
        text-align: left;
        font-weight: bold;
    }

    ul {
        /* border: 1px solid $border; */

        li {
            list-style: none;

            .item {
                font-size: 16px;
                color: $normal-color;
                line-height: 39px;
                display: flex;
                position: relative;
                justify-content: space-between;

                &:hover {
                    color: #7fad39;
                }
    
                span {
                    display: block;
                    position: relative;
                    height: 40px;
                    width: 37px;
                    z-index: 9;
                    &::after {
                        display: block;
                        position: absolute;
                        right: 18px;
                        top: 0;
                        content: "3";
                        font-family: "ElegantIcons";
                        font-size: 18px;
                    }
                }
            }

            .sublist {
                display: none;
                border: none;
                padding-left: 15px;
                padding-top: 10px;
                padding-bottom: 12px;
                .sub-item {
                    font-size: 16px;
                    line-height: 39px;
                    display: flex;
                    position: relative;
                    &:hover {
                        color: #7fad39;
                    }
                }
            }

            .lastlist {
                padding-left: 20px;
            }
        }
    }

    .latest-product__text {
        position: relative;

        h4 {
            margin-bottom: 45px;
        }

        .owl-carousel {

            .owl-nav {
                right: 0;
            }
        }
    }
}

.price-range-wrap {

    .range-slider {
        margin-top: 20px;

        .price-input {
            position: relative;

            &:after {
                position: absolute;
                left: 54px;
                top: 13px;
                height: 1px;
                width: 5px;
                background: #dd2222;
                content: "";
            }

            input {
                font-size: 16px;
                color: #dd2222;
                font-weight: 600;
                max-width: 60px;
                border: none;
                display: inline-block;
            }
        }
    }

    .price-range {
        border-radius: 0;

        &.ui-widget-content {
            border: none;
            background: #ebebeb;
            height: 5px;

            .ui-slider-handle {
                height: 13px;
                width: 13px;
                border-radius: 50%;
                background: $white-color;
                border: none;
                -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
                outline: none;
                cursor: pointer;
            }
        }

        .ui-slider-range {
            background: #dd2222;
            border-radius: 0;

            &.ui-corner-all.ui-widget-header:last-child {
                background: #dd2222;
            }
        }
    }
}

.sidebar__item__color {
    float: left;
    width: 40%;

    &.sidebar__item__color--white {

        label {

            &:after {
                border: 2px solid #333333;
                background: transparent;
            }
        }
    }

    &.sidebar__item__color--gray {

        label {

            &:after {
                background: #E9A625;
            }
        }
    }

    &.sidebar__item__color--red {

        label {

            &:after {
                background: #D62D2D;
            }
        }
    }

    &.sidebar__item__color--black {

        label {

            &:after {
                background: $heading-color-2;
            }
        }
    }

    &.sidebar__item__color--blue {

        label {

            &:after {
                background: #249BC8;
            }
        }
    }

    &.sidebar__item__color--green {

        label {

            &:after {
                background: #3CC032;
            }
        }
    }

    label {
        font-size: 16px;
        color: #333333;
        position: relative;
        padding-left: 32px;
        cursor: pointer;

        input {
            position: absolute;
            visibility: hidden;
        }

        &:after {
            position: absolute;
            left: 0;
            top: 5px;
            height: 14px;
            width: 14px;
            background: #222;
            content: '';
            border-radius: 50%;
        }
    }
}

.sidebar__item__size {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 10px;

    label {
        font-size: 12px;
        color: $para-color;
        display: inline-block;
        padding: 8px 25px 6px;
        background: $background;
        cursor: pointer;
        margin-bottom: 0;

        input {
            position: absolute;
            visibility: hidden;
        }
    }
}

.sidebar-cpanel {
    .sidebar-container {
        background-color: #7fad39;
        height: 100%;
        ul {
            li {
                a {
                    color: #fff !important;
                    font-weight: 600;
                    padding-right: 15px;
                    padding-left: 15px;
                }
                &:hover {
                    background-color: rgba(#000, .2);
                }
                .active {
                    background-color: rgba(#000, .2);
                }
            }
        }
    }
}