$primary-color: #7fad39;
$seconday-color: #120851;
$tertiary-color: #fcc301;
$white-color: #ffffff;
$heading-color: #111111;
$para-color: #6f6f6f;
$black-color: #000000;
$heading-color-2: #252525;
$normal-color: #1c1c1c;
$background: #f5f5f5;
$background-2: #f2f2f2;
$border: #ebebeb;
$border-1: #e1e1e1;

$primary-font: 'Poppins', sans-serif;
//$primary-font: 'Cairo', sans-serif;
//$primary-font: 'Roboto', sans-serif;
$fm-unna: 'Unna', serif;
$fm-nunito: 'Nunito Sans', sans-serif;