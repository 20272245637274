.form-cli {
    .form-group {
        display: column;
        input,
        select,
        .nice-select,
        textarea {
            border: 1px solid #dee2e6;
            border-radius: 5px;
            padding: 2px 10px;
            height: 42px;
            width: 100%;
        }
    }
}
.control-promo-banner {
    position: relative;
}
.remove-promo-banner {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 33%;
    font-size: 50px;
    opacity: 1 !important;
}
.modal-promo-banner-close {
    position: absolute;
    top: -2px;
    right: 2px;
}
.image-modal-banner {
    margin: 0 auto;
    display: block;
}