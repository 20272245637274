/*---------------------
  Shop Details
-----------------------*/
.product-details {
    padding-top: 80px;
}

.product__details__pic__item {
    margin-bottom: 20px;

    img {
        min-width: 100%;
    }
}

.product__details__pic__slider {

    img {
        cursor: pointer;
    }

    &.owl-carousel .owl-item img {
        width: auto;
    }
}

.product__details__text {

    h3 {
        color: $heading-color-2;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .product__details__rating {
        font-size: 14px;
        margin-bottom: 12px;

        i {
            margin-right: -2px;
            color: #EDBB0E;
        }

        span {
            color: #dd2222;
            margin-left: 4px;
        }
    }

    .product__details__price {
        font-size: 30px;
        color: #dd2222;
        font-weight: 600;
        margin-bottom: 15px;
    }

    // inside details page
    .product__details__note {
        border: 1px solid #dee2e6 !important;
        height: 100px !important;
        margin: 0 0 10px !important;
        display: flex;
        flex: auto;
        width: 374px;
    }

    .product__details__quantity {
        display: inline-block;
        margin-right: 6px;
        width: 140px;
    }

    .pro-qty {
        width: 140px;
        height: 50px;
        display: inline-block;
        position: relative;
        text-align: center;
        background: $background;
        margin-bottom: 5px;

        input {
            height: 100%;
            font-size: 16px;
            color: $para-color;
            width: 50px;
            border: none;
            background: $background;
            text-align: center;
        }

        .qtybtn {
            width: 35px;
            font-size: 16px;
            color: $para-color;
            cursor: pointer;
            display: inline-block;
        }
    }

    p {
        margin-bottom: 45px;
    }

    .primary-btn {
        padding: 16px 28px 14px;
        margin-right: 6px;
        margin-bottom: 5px;
    }

    .heart-icon {
        display: inline-block;
        font-size: 16px;
        color: $para-color;
        padding: 13px 16px 13px;
        background: $background;
    }

    ul {
        border-top: 1px solid $border;
        padding-top: 40px;
        margin-top: 50px;

        li {
            font-size: 16px;
            color: $normal-color;
            list-style: none;
            line-height: 36px;

            b {
                font-weight: 600;
                width: 170px;
                display: inline-block;
            }

            span {

                samp {
                    color: #dd2222;
                }
            }

            .share {
                display: inline-block;

                a {
                    display: inline-block;
                    font-size: 15px;
                    color: $normal-color;
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

// shop page card
.product__details__note {
    border: 1px solid #dee2e6 !important;
    height: 60px;
    display: flex;
    margin: 10px;
    flex: auto;
    width: 100%;
}

.product__details__quantity {
    display: inline-block;
    margin-right: 6px;
    width: 100px;
}

.pro-qty {
    width: 100px;
    height: 41px;
    display: inline-block;
    position: relative;
    text-align: center;
    background: $background;
    margin-bottom: 0;

    input {
        height: 100%;
        font-size: 16px;
        color: $para-color;
        width: 40px;
        border: none;
        background: $background;
        text-align: center;
    }

    .qtybtn {
        width: 25px;
        font-size: 16px;
        color: $para-color;
        cursor: pointer;
        display: inline-block;
    }
}

.add-to-cart {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 50%;
}

.product__details__tab {
    padding-top: 85px;

    .nav-tabs {
        border-bottom: none;
        justify-content: center;
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 12px;
            height: 1px;
            width: 370px;
            background: $border;
            content: '';
        }

        &:after {
            position: absolute;
            right: 0;
            top: 12px;
            height: 1px;
            width: 370px;
            background: $border;
            content: '';
        }

        li {
            margin-bottom: 0;
            margin-right: 65px;

            &:last-child {
                margin-right: 0;
            }

            a {
                font-size: 16px;
                color: #999999;
                font-weight: 600;
                border: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                padding: 0;
            }
        }
    }

    .product__details__tab__desc {
        padding-top: 44px;

        h6 {
            font-weight: 600;
            color: #333333;
            margin-bottom: 26px;
        }

        p {
            color: #666666;
        }
    }
}

/*---------------------
  Shop Details
-----------------------*/
.related-product {
    padding-bottom: 30px;
}

.related__product__title {
    margin-bottom: 70px;
}